import { defineI18nConfig } from '#i18n'
export default defineI18nConfig(() => ({
  availableLocales: ['en', 'ru'],
  fallbackLocale: 'en',
  legacy: false,
  pluralRules: {
    ru: function (choice: number, choicesLength: number): number {
      if (choice === 0 && choicesLength === 4) {
        return 0
      }
      const lengthCoefficient = choicesLength === 4 ? 1 : 0
      if (choice % 10 === 1 && choice % 100 !== 11) {
        return 0 + lengthCoefficient
      } else if (choice % 10 >= 2 && choice % 10 <= 4 && (choice % 100 < 10 || choice % 100 >= 20)) {
        return 1 + lengthCoefficient
      } else {
        return 2 + lengthCoefficient
      }
    }
  }
})
)
